import React from "react";

function NotFoundPage() {
  return (
    <div style={{ padding: "71px 48px" }}>
      <p>{"This page doesn't exist."}</p>
    </div>
  );
}

export default NotFoundPage;
